<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import simplebar from "simplebar-vue";
import gpselect from "../../components/gpselect";
import {lookup2,getLookupItemText,trueText} from '@/apis/common'
import {queryStatisticsWorkingday} from '@/apis/statistics'
import store from '@/state/store'
export default {
  page: {
    title: "工时统计",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,simplebar,gpselect},
    data() {
        return {
            showType: "list",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            tableData: [],
            items:[],
            showDetail:false,
            curData:{},
            saveFileAction:"",
            filter:{
                auditor:"",
                cycle:"",
                status:""
            },
            lookupRawData:{},
            lookupData:{
                orderType:[],
                orderStatus:[],
                orderSource:[],
                cnty:[],
                yesNo:[{label:"是",labelEn:"Yes",value:"Y"},{label:"否",labelEn:"No",value:"N"}]
            },
            pageRequest:{
                pageNum:1,
                pageSize:10
            }
        };
    },
    computed: {
        myHiggFemFormUrl(){
            return "http://application.greenpont.com/higg-form/"+store.state.auth.currentUser.userId
        },
        mySlcpFormUrl(){
            return "http://application.greenpont.com/slcp-form/"+store.state.auth.currentUser.userId
        },
        myGeneralFormUrl(){
            return "http://application.greenpont.com/general-form/"+store.state.auth.currentUser.userId
        },
    },
    watch:{
        "pageRequest.pageNum"() {
            this.loadData()
        },
        filter:{
            handler(){
                this.loadData();
            },
            deep:true
        }
    },
    created(){
        this.profileFormInit = {...this.profileForm}
        this.init();
        this.loadData();
    },
    mounted() {
    },
    methods: {
        trueText,
        async init(){
            this.lookupRawData = await lookup2("ORDER-TYPE,ORDER-STATUS,ORDER-SOURCE,CNTY");
            this.lookupData.orderType = this.lookupRawData["ORDER-TYPE"];
            this.lookupData.orderStatus = this.lookupRawData["ORDER-STATUS"];
            this.lookupData.orderSource = this.lookupRawData["ORDER-SOURCE"];
            this.lookupData.cnty = this.lookupRawData["CNTY"];
        },
        getParams(){
            let params = {...this.pageRequest,filter:this.filter}
            if(this.clientNo!=undefined && this.clientNo!=null){
                params.filterExt = {"clientNo":this.clientNo}
            }
            return params
        },
        loadData(){
            const params = this.getParams();
            queryStatisticsWorkingday(params).then((res)=>{
                this.tableData = res.data;
                this.totalRows = res.page.total;
            });
        },
        handleDetail(item){
            this.curData = item
            this.$refs.myFormModal.show()
        },
        formatLookupText(typeCode,value){
            return getLookupItemText(this.lookupRawData[typeCode],value);
        },
        handleCloseOrderStatusModal(){
            this.curData = {}
            this.orderStatusShow = false
            this.loadData()
        },
    }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h5 class="card-title">{{$t("workingday.name")}}</h5>
                        </div>
                    </div>
                </div><!-- end row -->
                <b-modal id="modal-standard" :title="$t('workingday.detail')" title-class="font-18" ref="myFormModal" hide-footer>                    
                   <div class="row">
                        <div class="col-md-6 mb-3"><strong>{{$t("workingday.auditor")}}：<span class="auditorName">{{ curData.auditor }}</span></strong></div>
                        <div class="col-md-6 mb-3"><strong>{{$t("workingday.cycle")}}：{{ curData.cycle }}</strong></div>
                        <div class="col-md-6 mb-3">工作日验证人天：{{ curData.workDays }}</div>
                        <div class="col-md-6 mb-3">节假日验证人天：{{ curData.weekendWorkDays }}</div>
                        <div class="col-md-6 mb-3">工作日差旅人天：{{ curData.travelDays }}</div>
                        <div class="col-md-6 mb-3">节假日差旅人天：{{ curData.weekendTravelDays }}</div>      
                        <div class="col-md-6 mb-3">工作日观察人天：{{ curData.observerWorkDays }}</div>
                        <div class="col-md-6 mb-3">节假日观察人天：{{ curData.observerWeekendWorkDays }}</div>
                        <div class="col-md-6 mb-3">工作日观察差旅人天：{{ curData.observerTravelDays }}</div>
                        <div class="col-md-6 mb-3">节假日观察差旅人天：{{ curData.observerWeekendTravelDays }}</div>       
                        <div class="col-md-12">
                            <button type="button" class="btn btn-info" >确认统计</button>                      
                            <button type="button" class="btn btn-light ms-1">争议</button>
                        </div>                                   
                   </div>
                </b-modal>
                <div class="table-responsive">
                    <simplebar class="h-100">
                    <table class="table align-middle table-nowrap table-check">
                        <thead>
                            <tr>
                                <th style="width: 80px; min-width: 80px;" scope="col">{{$t("workingday.cycle")}}</th>
                                <th scope="col">{{$t("workingday.auditor")}}</th>
                                <th scope="col">{{$t("workingday.status")}}</th>
                                <th scope="col">{{$t("workingday.confirmDate")}}</th>
                                <th style="width: 80px; min-width: 80px;">{{$t("actions.actions")}}</th>
                            </tr><!-- end tr -->
                        </thead><!-- end thead -->
                        <tbody>
                            <tr class="data-filter">
                                <td>
                                    <input type="text" class="form-control" v-model="filter.cycle">
                                </td>
                                <td><input type="text" class="form-control" v-model="filter.auditor"></td>
                                <td>
                                    <gpselect
                                    :data="lookupData.orderStatus"
                                    v-model="filter.orderStatus"
                                    />
                                </td>
                                <td>
                                </td>
                                <td>                                       
                                </td>
                            </tr><!-- end tr -->
                            <tr v-for="row in tableData" :key="row.id">
                                <td><a class="text-body fw-medium" href="javascript:" @click="handleDetail(row)">{{row.cycle}}</a></td>
                                <td class="auditorName">{{row.auditor}}</td>
                                <td>{{formatLookupText('CNTY',row.status)}}</td>
                                <td>{{row.createDate}}</td>
                                <td><a class="text-body fw-medium" href="javascript:" @click="handleDetail(row)">{{$t("common.view")}}</a>                                           
                                </td>
                            </tr><!-- end tr -->
                        </tbody><!-- end tbody -->
                    </table><!-- end table -->
                    </simplebar>
                </div>
                <div class="row g-0 text-center text-sm-start" v-if="totalRows>0">
                    <div class="col-sm-6">
                        <div>
                            <p class="mb-sm-0">Showing {{(pageRequest.pageNum-1)*pageRequest.pageSize+1}} to {{pageRequest.pageNum*pageRequest.pageSize}} of {{totalRows}} entries</p>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-sm-6">
                        <ul class="pagination pagination-rounded justify-content-center justify-content-sm-end mb-sm-0">
                            <b-pagination v-model="pageRequest.pageNum" :total-rows="totalRows" :per-page="pageRequest.pageSize"></b-pagination>
                        </ul><!-- end ul -->
                    </div><!-- end col -->
                </div><!-- end row -->        
            </div><!-- end card -->                    
                </div>
            </div>
        </div><!-- end col -->
    </div><!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.auditorName::first-letter {
  text-transform: uppercase;
}
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>